.question_popup {
  position: absolute;
  max-width: 750px;
  width: 100%;
  bottom: 80px;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;

  &__content {
    width: 95%;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: var(--primaryColor);
    box-shadow: 0px 3px 6px #00000029;
  }

  &__close {
    width: 18px;
    height: 18px;
    cursor: pointer;
    align-self: flex-end;
  }

  &__title {
    color: var(--white);
    font-size: 40px;
    line-height: 41px;
    text-align: center;
    padding-bottom: 20px;
    margin-top: 5px;
    line-height: 35px;
    font-weight: 400;
  }

  &__subtitle {
    @extend .question_popup__title;

    font-size: 20px;
    line-height: 24px;
    margin-top: 5px;
    font-weight: 300;
  }

  &__button {
    width: 90%;
    margin-bottom: 15px;
  }
}
