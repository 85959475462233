.shop {
  width: 100%;
  background-color: var(--primaryColorDark);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  &__scanner {
    margin-top: 50px;
    padding-bottom: 14px;
  }

  &__first_scan {
    width: 100%;
    border-top: 1px solid var(--primaryColor);
  }

  &__cart {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 15px;
    height: 40vh;
  }

  &__cart--without_scanner {
    width: 100%;
    overflow-y: scroll;
    height: calc(100vh - 330px);
  }

  &__button_container {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 5px;
    width: 100%;
    max-width: 750px;
    background-color: var(--primaryColorDark);
  }

  &__button {
    width: 80%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &__confirm {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0px;
    width: 95%;
    max-width: 750px;
    background-color: var(--primaryColorDark);
  }
}
