.leave_store {
  max-width: 750px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--primaryColorDark);
  height: calc(100vh - 75px);

  &__content {
    height: 100%;
    width: 100%;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__welcome_message {
    width: 75%;
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    font-size: 30px;
    line-height: 33px;
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
  }

  &__subtitle {
    font-size: 19px;
    font-weight: 300;
    text-align: center;
    padding-bottom: 20px;
  }

  &__bottom {
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  &__alert_text {
    font-size: 20px;
    color: var(--white);
    font-weight: 300;
    line-height: 24px;
    width: 80%;
    text-align: center;
    animation: fadeIn linear 3s;
    -webkit-animation: fadeIn linear 3s;
    -moz-animation: fadeIn linear 3s;
    -o-animation: fadeIn linear 3s;
    -ms-animation: fadeIn linear 3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__button {
    width: 75%;
  }

  &__links {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 15px;
    a {
      color: var(--white);
      font-weight: 300;
      font-size: 18px;
    }
  }

  &__link {
    padding-bottom: 5px;
  }
}
