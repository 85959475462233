/* default colors */
body {
  --primaryColorDark: #0d551f;
  --primaryColor: #515151;
  --doorColor: #157946;
  --doorColorLight: #51ad7e;
  --doorColorLuminous: #0ceb79;
  --white: #ffffff;
  --green: #157946;
  --red: #ed1c24;
  --darkRed: #aa141c;
}
