.first_scan {
  max-width: 750px;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  padding-top: 10px;

  &__content {
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primaryColor);
    box-shadow: 0px 3px 6px #00000029;
  }

  &__text {
    color: var(--white);
    font-size: 20px;
    text-align: center;
    line-height: 24px;
    font-weight: 300;
    padding-top: 10px;
    width: 90%;
    text-align: center;
  }

  &__background_text {
    color: var(--primaryColor);
    font-size: 20px;
    text-align: center;
    line-height: 24px;
    font-weight: 300;
    padding-top: 10px;
    width: 90%;
    text-align: center;
  }

  &__button {
    width: 90%;
    border-top: 3px dotted var(--white);
    padding-top: 24px;
  }
}
