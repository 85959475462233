.popup_template {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primaryColorDark);
    z-index: 999999;
    transition: all 0.5s ease-in-out;
    overflow: auto;
}




