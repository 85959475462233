.cart_item {
  border-bottom: 1px solid var(--primaryColor);
  color: var(--white);
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  background-color: var(--primaryColorDark);
  padding: 5px;

  &__item_name {
    font-size: 18px;
    font-weight: 400;
    width: 85%;
  }

  &__weight_price {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    padding-top: 5px;
  }

  &__weight {
    font-size: 17px;
    font-weight: 300;
    max-width: 70%;
    padding-right: 5px;
  }

  &__price {
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
    font-size: 18px;
    font-weight: 400;
  }
}

.cart_item--receipt {
  @extend .cart_item;

  border-bottom: 1px solid var(--white);
  color: var(--white);
  background-color: var(--primaryColor);
}
