.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba($color: #1A261F, $alpha: 0.99);
    width: 100%;
    height: 100%;
  
    &__lottie {
      width: 250px;
      height: 250px;
    }
  }
  