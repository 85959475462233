.button_component {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: var(--white);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 10px;
  cursor: pointer;
  border-style: none;

  &__text {
    font-size: 20px;
    line-height: 40px;
    color: var(--primaryColorDark);
    letter-spacing: 0.7px;
  }

  &__left_icon {
    position: absolute;
    left: 10px;
    width: 24px;
    height: 24px;
  }

  &__right_icon {
    position: absolute;
    right: 10px;
    height: 24px;
  }
}

.button--disabled {
  @extend .button_component;

  background-color: #5A5A5A;
  cursor: auto;
}

.green_button_component {
  @extend .button_component;

  background-color: var(--primaryColor);

  p {
    color: var(--white);
  }
}

@media screen and (max-width: $mobile) {
  .button_component {
    &__text {
      font-size: 16px;
    }
  }
}
