.red_slide_button {
  width: 100%;
  height: 60px;

  &__container {
    border: 7px solid var(--white);
    width: 100%;
    height: 100%;
    background-color: $red;
    border: 7px solid var(--white);
    border-radius: 50px;
    position: relative;
    overflow: hidden;
  }

  &__slider {
    width: 100%;
    position: absolute;
    height: 46px;
    top: 0;
    left: 46px;
    margin-left: -100%;
    background: $dark-red;
    border-radius: 50px;
    z-index: 100;
    cursor: pointer;
  }

  &__arrow {
    position: absolute;
    transform: rotate(45deg);
    border: 3px solid var(--white);
    border-radius: 2px;
    height: 18px;
    width: 18px;
    top: 44%;
    right: 18px;
    margin-top: -6px;
    border-left-color: transparent;
    border-bottom-color: transparent;
    transform-origin: center;
    z-index: 10;
  }

  &__text {
    position: absolute;
    top: -1px;
    left: 30px;
    right: 0;
    height: 60px;
    line-height: 50px;
    text-align: center;
    font-family: "New Hero", sans-serif;
    letter-spacing: 1px;
    font-size: 18px;
    color: var(--white);
  }
}

.red_slide_button,
.red_slide_button * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                        not supported by any browser */
}
