.scandit_window {
  max-width: 750px;
  width: 100%;
  height: 100%;
}

.scandit-container {
  max-height: 30vh;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.scandit {
  .scandit-viewfinder {
    border: 4px solid var(--primaryColor) !important;
  }
}

