.error_popup {
  position: absolute;
  max-width: 750px;
  width: 100%;
  top: 10px;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;

  &__content {
    width: 95%;
    height: 85vh;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: var(--red);
    box-shadow: 0px 3px 6px #00000029;
  }

  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__close {
    width: 18px;
    height: 18px;
    cursor: pointer;
    align-self: flex-end;
  }

  &__title {
    color: var(--white);
    font-size: 28px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
    line-height: 24px;
    font-weight: 400;
  }

  &__subtitle {
    @extend .error_popup__title;

    padding-top: 10px;
    padding-bottom: 20px;
  }

  &__text {
    @extend .error_popup__title;

    font-size: 20px;
    font-weight: 300;
  }

  &__button {
    width: 90%;
    margin-bottom: 15px;
  }
}
