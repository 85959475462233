.terms-and-conditions {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  background-color: var(--primaryColorDark);

  &__lang_dropdown_button {
    position: absolute;
    z-index: 999;
    width: 180px;
    top: 10px;
  }

  .card {
    color: var(--primaryColorDark);
    background-color: var(--white);
    height: 100%;
    width: 95%;
    margin: 100px auto 0 auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 30px;

    .title {
      margin-bottom: 15px;
      font-size: 23px;
      text-align: center;
      color: var(--primaryColorDark);
    }

    .content {
      width: 100%;
      color: var(--primaryColorDark);
      overflow: auto;
      height: calc(100vh - 50%);

      a {
        color: var(--primaryColorDark);
      }
    }

    button {
      margin-top: 15px;
    }
  }
}
