.receipt {
  width: 100%;
  color: var(--white);
  height: calc(100vh - 110px);
  background-color: var(--primaryColor);
  border-radius: 8px;

  &__content {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__title {
    font-size: 20px;
    line-height: 26px;
    font-weight: 300;
    padding-bottom: 10px;
  }

  &__total {
    font-size: 30px;
    line-height: 34px;
    padding-bottom: 10px;
    font-weight: 400;
  }

  &__cart {
    height: 100%;
  }

  &__render {
    border-top: 3px dotted var(--white);
    border-bottom: 3px dotted var(--white);
    overflow: auto;
    height: calc(100vh - 365px);

    :last-child {
      border-bottom: none;
    }
  }

  &__buttons {
    width: 100%;
    background-color: var(--primaryColor);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button_top {
    width: 70%;
    padding-bottom: 12px;
  }

  &__button_bottom {
    width: 70%;
    padding-bottom: 20px;
  }
}
