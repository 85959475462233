.ReactSwipeButton {
  float: left;
  width: 100%;
  height: 50px;
  position: relative;
}
.ReactSwipeButton,
.ReactSwipeButton * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    not supported by any browser */
}
.rsbContainer {
  float: left;
  width: 100%;
  height: 100%;
  background: var(--doorColor);
  border-radius: 50px;
  position: relative;
  /* box-shadow: inset 1px 1px 4px rgba(0,0,0,0.1); */
  overflow: hidden;
}
// .rsbContainerUnlocked {
//   width: 50% !important;
//   margin-left: 25%;
//   transition: 0.5s;
//   cursor: default;
// }
// .rsbContainerUnlocked .rsbcSlider {
//   left: 100% !important;
//   cursor: default;
//   pointer-events: none;
// }
// .rsbContainerUnlocked .rsbcSliderArrow {
//   transition: 0.5s;
//   margin-right: -60px;
// }
// .rsbContainerUnlocked .rsbcSliderCircle {
//   transition: 0.5s;
//   margin-right: -60px;
// }

.rsbContainerUnlocked .rsbcSlider {
  background-color: var(--doorColorLight);
}

.rsbcSlider {
  float: left;
  width: 100%;
  position: absolute;
  height: 50px;
  top: 0;
  left: 50px;
  margin-left: -100%;
  background: var(--doorColorLight);
  border-radius: 25px;
  z-index: 100;
  /* box-shadow: 1px 1px 5px rgba(0,0,0,0.3); */
  cursor: pointer;
}

.rsbContainerFail {
  @extend .rsbContainer;
  background-color: var(--red);
}

.rsbcSliderFail {
  @extend .rsbcSlider;
  background-color: var(--darkRed);
}

.rsbcSliderArrow {
  float: left;
  position: absolute;
  transform: rotate(45deg);
  border: 2px solid var(--white);
  height: 12px;
  width: 12px;
  top: 50%;
  right: 22px;
  margin-top: -6px;
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform-origin: center;
  z-index: 10;
}
// .rsbcSliderCircle {
//   position: absolute;
//   right: 0;
//   background: black;
//   opacity: 45%;
//   top: 0;
//   height: 50px;
//   width: 50px;
//   border-radius: 100%;
// }
// .rsbcText {
//   float: left;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   height: 50px;
//   line-height: 50px;
//   text-align: center;
//   font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
//     "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
//   letter-spacing: 2px;
//   font-size: 13px;
//   color: #aaa;
// }
