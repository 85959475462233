.support-form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  padding: 20px;
  background-color: var(--primaryColorDark);

  &__lang_dropdown_button {
    position: absolute;
    z-index: 1;
    width: 180px;
    top: 10px;
  }

  .card {
    background-color: var(--primaryColor);
    height: 100%;
    width: 95%;
    margin: 55px auto 0 auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 30px 20px 30px 20px;

    .title {
      margin-bottom: 30px;
      font-size: 30px;
      text-align: center;
      color: var(--white);
    }

    .content {
      width: 100%;
      color: var(--white);
      font-size: 18px;

      .form-group {
        margin: 20px 0;
      }

      input {
        width: 100%;
        max-width: 100%;
        height: 2rem;
      }

      textarea {
        width: 100%;
        resize: none;
        max-width: 100%;
      }

      input, textarea {
        border: none;
        margin-top: 10px;
        font-family: $main-font;
        padding: 10px;
        &:focus, &:active {
          border: none;
          outline: none;
        }
      }
    }

    button {
      margin-top: 30px;
    }
  }

}