.general_popup {
  position: absolute;
  max-width: 750px;
  width: 100%;
  top: 10px;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;

  &__content {
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--white);
    box-shadow: 0px 3px 6px #00000029;
  }

  &__head {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  &__close {
    width: 15px;
    height: 15px;
    cursor: pointer;
    align-self: flex-end;
  }

  &__title {
    margin-bottom: 15px;
    font-size: 23px;
    text-align: center;
    color: var(--primaryColorDark);
  }

  &__text_container {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 8px;
  }

  &__text {
    @extend .general_popup__title;

    width: 100%;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    text-align: left;
    border-bottom: none;
  }

  &__button {
    width: 100%;
  }
}
