.confirm {
  max-width: 750px;
  width: 100%;
  transition: all 0.5s ease-in-out;

  &__content {
    border-radius: 8px;
    padding: 10px 32px 0px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: var(--primaryColor);
    box-shadow: 0px 3px 6px #00000029;
  }

  &__image {
    width: 80px;
    height: 80px;
  }

  &__title {
    color: var(--white);
    font-size: 20px;
    text-align: center;
    margin-top: 5px;
    line-height: 22px;
    font-weight: 400;
    width: 80%;
  }

  &__switch_container {
    display: flex;
    align-items: center;
  }

  &__switch_text_container {
    display: flex;
    color: var(--white);
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-left: 15px;
  }

  &__t_and_c_link {
    text-decoration: underline;
    cursor: pointer;
  }

  &__close_button {
    width: 100%;
    padding-top: 18px;
    margin-bottom: 18px;
  }

  &__button {
    width: 100%;
    padding-top: 18px;
    margin-bottom: 24px;

    .changePayment {
      text-align: center;
      color: white;
      text-decoration: underline;
      margin-top: 24px;

      &.disabled {
        color: #5A5A5A;
      }
    }
  }
}
