.select_language_popup {
  position: absolute;
  max-width: 750px;
  width: 100%;
  top: 10px;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--white);
    box-shadow: 0px 3px 6px #00000029;
  }

  &__head {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  &__close {
    width: 15px;
    height: 15px;
    cursor: pointer;
    align-self: flex-end;
  }

  &__title {
    width: 100%;
    color: var(--primaryColorDark);
    font-size: 20px;
    text-align: center;
    padding-bottom: 10px;
    line-height: 27px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  &__text_container {
    width: 100%;
    overflow: auto;
    overflow-y: scroll;
    padding-top: 10px;
    padding-bottom: 8px;
    height: 100%;
  }

  &__text {
    @extend .general_popup__title;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    text-align: left;

    button {
      width: 100%;
    }
  }
}
