.unlock_store {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;

  &__lang_dropdown_button {
    position: absolute;
    width: 180px;
    top: 10px;
  }

  &__welcome {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }

  &__welcome_message {
    width: 90%;
    color: var(--white);
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;
    text-align: center;
  }

  &__logo {
    max-height: 48px;
    max-width: 150px;
    object-fit: contain;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  &__bottom {
    height: 125px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__alert_text {
    font-size: 20px;
    color: var(--white);
    font-weight: 300;
    line-height: 24px;
    width: 85%;
    text-align: center;
    padding-top: 20px;
    animation: fadeIn linear 3s;
    -webkit-animation: fadeIn linear 3s;
    -moz-animation: fadeIn linear 3s;
    -o-animation: fadeIn linear 3s;
    -ms-animation: fadeIn linear 3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__in_store_button {
    width: 85%;
    margin-top: 25px;
    padding-bottom: 20px;
  }

  &__links {
    text-align: center;
    padding-bottom: 15px;
    font-weight: 300;
    font-size: 19px;
    text-decoration: underline;
    color: var(--white);
    cursor: pointer;

    li {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        margin-right: 5px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .unlock_store {
    &__welcome_message {
      width: 95%;
      font-size: 27px;
      line-height: 27px;
    }

    &__logo {
      max-height: 40px;
      max-width: 120px;
      object-fit: contain;
      margin-top: 10px;
    }
  }
}
