// Breakpoints
$mobile: 420px - 1px;

// Colors
// $dark-green: #1a261f;
// $green: #157946;
$light-green: #51ad7e;
$luminous-green: #0ceb79;
$white: #ffffff;
$red: #ec1b24;
$dark-red: #aa1017;

// Typography

$main-font: "New Hero", sans-serif;
