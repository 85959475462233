.navbar {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  &__header {
    background-color: var(--primaryColorDark);
    max-width: 750px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 80;
    position: fixed;
    margin-left: -10px;
    padding-right: 10px;
  }

  &__hidden_element {
    visibility: hidden;
  }

  &__logo {
    height: 18px;
  }

  &__hamburger {
    cursor: pointer;
  }

  &__sidebar_position {
    position: fixed;
    max-width: 750px;
    width: 100%;
    z-index: 1000;
  }

  &__overlay {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
  }

  &__sidebar {
    background-color: var(--primaryColor);
    width: 330px;
    height: 100vh;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    right: -100%;
  }

  &__sidebar--active {
    @extend .navbar__sidebar;

    right: 0;
    position: absolute;
    z-index: 1000;
  }

  &__close {
    padding: 10px 20px 20px 0px;
    align-self: flex-end;
    cursor: pointer;
    height: 20px;
  }

  &__sidebar_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__language_button {
    width: 60%;
    height: 60px;
  }

  &__hilfe {
    color: var(--white);
    font-size: 24px;
    font-weight: 600;
    padding-top: 85px;
    padding-bottom: 10px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: center;

    a, p {
      color: var(--white);
      font-size: 18px;
      font-weight: 300;
    }
  }

  &__link {
    padding-bottom: 15px;

    a, p {
      img {
        width: 30px;
        margin-right: 5px;
      }
      display: flex;
      align-items: center;
    }
  }

  &__emergency_button {
    width: 90%;
    position: absolute;
    bottom: 110px;
    right: 22px;
  }

  &__agb_impressum {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 300;
    font-size: 15px;

    color: var(--white);

    li {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.sidebar-enter {
  opacity: 0;
}
.sidebar-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.sidebar-exit {
  opacity: 1;
}
.sidebar-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
