//************GLOBAL**************
@import "./variables";
@import "./fonts/fonts.scss";
@import "./dynamic";
//Components

@import "../components/Button/Button.scss";
@import "../components/ExpandableButton/ExpandableButton.scss";
@import "../components/Cart/Cart.scss";
@import "../components/CartItem/CartItem.scss";
@import "../components/Scandit/Scandit.scss";
@import "../components/Shop/Shop.scss";
@import "../components/UnlockAnimation/UnlockAnimation.scss";
@import "../components/UnlockStore/UnlockStore.scss";
@import "../components/Navbar/Navbar.scss";
@import "../components/Confirm/Confirm.scss";
@import "../components/Receipt/Receipt.scss";
@import "../components/FirstScan/FirstScan.scss";
@import "../components/Loader/Loader.scss";
@import "../components/RedAnimatedButton/RedAnimatedButton.scss";
@import "../components/RedSlideButton/RedSlideButton.scss";

//Popups

@import "../components/popups/QuestionPopup/QuestionPopup.scss";
@import "../components/popups/ErrorPopup/ErrorPopup.scss";
@import "../components/popups/CustomErrorPopup/CustomErrorPopup.scss";
@import "../components/popups/GeneralPopup/GeneralPopup.scss";
@import "../components/popups/SelectLanguagePopup/SelectLanguagePopup.scss";
@import "../components/popups/SupportPagePopup/SupportPagePopup.scss";

//Templates

@import "../templates/PopupTemplate/PopupTemplate.scss";

//***************Pages***************

@import "../pages/Shop/Shop.scss";
@import "../pages/UnlockStore/UnlockStore.scss";
@import "../pages/LeaveStore/LeaveStore.scss";
@import "../pages/Landing/Landing.scss";
@import "../pages/ReceiptPage/ReceiptPage.scss";
@import "../pages/PaymentTokenization/styles";
@import "../pages/TermsAndConditions/styles";
@import "../pages/SupportForm/styles";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  max-width: 750px;
  width: 100%;
  background-color: var(--primaryColorDark);
  margin: auto;
}

ul,
ol {
  list-style: none;
}

// ^^^^^MEDIA QUERIES^^^^^

@media only screen and (min-width: 700px) {
  .error_popup {
    bottom: 10px;

    &__content {
      height: 95vh;
    }
  }
  .general_popup {
    bottom: 10px;
  }
  .question_popup {
    bottom: 10px;
  }
}

@media only screen and (min-width: 700px) {
  .navbar__sidebar {
    height: 100vh;
  }
}

@media only screen and (min-width: 700px) {
  .landing {
    height: 100vh;
  }
}

@media only screen and (min-width: 700px) {
  .unlock_store {
    height: 100vh;
  }
}

@media only screen and (min-width: 700px) {
  .leave_store {
    height: calc(100vh - 75px);
  }
}

@media only screen and (min-width: 700px) {
  .first_scan {
    height: calc(70vh - 75px);
  }
}

@media only screen and (min-width: 700px) {
  .shop {
    overflow: visible;

    &__cart {
      margin-bottom: 20px;
    }
    &__button {
      padding-top: 16p;
      padding-bottom: 20px;
    }
    &__confirm {
      bottom: 10px;
    }
  }
}
