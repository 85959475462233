.expandable_button {
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-style: none;

  &__head {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    cursor: pointer;
  }

  p {
    font-size: 16px;
    color: var(--primaryColorDark);
  }

  &__left_icon {
    position: absolute;
    left: 10px;
    width: 25px;
    height: 25px;
  }

  &__right_icon {
    position: absolute;
    right: 10px;
    width: 25px;
    height: 25px;
  }

  &__expand {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;

    ul {
      color: var(--primaryColorDark);

      li {
        font-size: 16px;
        padding-bottom: 10px;
        cursor: pointer;
      }
    }
  }
}

.green_expandable_button {
  @extend .expandable_button;

  background-color: var(--primaryColor);

  p {
    color: var(--white);
  }

  &__expand {
    @extend .expandable_button__expand;

    ul {
      color: var(--white);
    }
  }
}

.expand-enter {
  opacity: 0;
}
.expand-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.expand-exit {
  opacity: 1;
}
.expand-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.expandable_button--expanded {
  @extend .expandable_button;

  filter: drop-shadow(0px 0px 2px var(--white));
  transition: filter 0.3s ease-in-out;
}

.green_expandable_button--expanded {
  @extend .green_expandable_button;

  filter: drop-shadow(0px 0px 10px var(--primaryColor));
  transition: filter 0.3s ease-in-out;
}
