.unlock_animation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  &__circle {
    width: 100%;

    & > div > div {
      height: 160px !important;
    }
  }

  &__circle_content {
    position: absolute;
    bottom: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__lock_icon {
    padding-top: 24px;
  }

  .timer {
    width: 200px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
  }

  .circle_text {
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    width: 70%;
  }

  .circle_text--clicked {
    padding-top: 10px;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    width: 70%;
    animation: fadeIn linear 1s;
    -webkit-animation: fadeIn linear 1s;
    -moz-animation: fadeIn linear 1s;
    -o-animation: fadeIn linear 1s;
    -ms-animation: fadeIn linear 1s;
  }

  .slide_button_container {
    width: 176px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
