.landing {
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;

  &__dropdown_button {
    position: absolute;
    width: 180px;
    top: 10px;
  }

  &__text_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-top: 100px;
    color: var(--white);
    font-size: 36px;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
  }

  &__text {
    padding-top: 10px;
    padding-bottom: 15px;
    width: 85%;
    color: var(--white);
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &_flex {
      img {
        width: 30px;
        margin-right: 5px;
      }
      display: flex;
      align-items: center;
    }
  }

  &__scanner {
    width: 70%;
  }
}
