.red_animated_button {
  width: 100%;
  cursor: pointer;

  &__parent {
    width: 100%;
    height: 60px;
    border-radius: 60px;
    border: 7px solid var(--white);
    background: $red;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: var(--white);
      font-size: 20px;
      line-height: 27px;
      padding-bottom: 4px;
    }
  }

  &__parent--clicked {
    width: 100%;
    height: 60px;
    border-radius: 60px;
    border: 7px solid var(--white);
    background: $red;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: var(--white);
      font-size: 20px;
      line-height: 27px;
      padding-bottom: 4px;
    }
  }

  &__placeholder_child {
    width: 36px;
    height: 36px;
    border-radius: 30px;
    border: 2px solid $dark-red;
    background: $dark-red;
    position: absolute;
    top: 5px;
    left: 5px;
  }

  &__arrow {
    position: absolute;
    top: 12px;
    left: 17px;
    height: 22px;
  }

  &__arrow--clicked {
    position: absolute;
    top: 6px;
    left: 12px;
    height: 24px;
  }

  &__child {
    @extend .red_animated_button__placeholder_child;

    animation: slide 2s;
    animation-name: sidebar_button;
  }

  @keyframes sidebar_button {
    0% {
      left: 0px;
    }
    50% {
      left: 210px;
    }
    100% {
      right: 0px;
    }
  }
}
