.receipt_page {
  max-width: 750px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--primaryColorDark);

  &__receipt {
    padding-top: 50px;
  }
}
