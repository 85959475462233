.cart {
  width: 100%;
  color: var(--white);
  border-bottom: 4px solid var(--primaryColor);
  border-top: 1px solid var(--primaryColor);
  background-color: var(--primaryColorDark);

  &__render {
    display: flex;
    flex-direction: column;
  }

  &__no_items {
    padding-top: 10px;
  }

  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 2px;

    p {
      font-size: 26px;
      line-height: 28px;
    }
  }

  &__basket {
    display: flex;
    align-items: center;
    p {
      padding-left: 20px;
    }
  }

  .swipeRightContent {
    background-color: var(--green);
    width: 100%;
    height: 90px;
    display: flex;
  }

  .swipeLeftContent {
    @extend .swipeRightContent;
    background-color: var(--red);
    justify-content: flex-end;
  }

  .swipe_icon {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swipe_text {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

//styles from library

.swipeable-list {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.swipeable-list-item {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}

.swipeable-list-item__content {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--white);
  height: 100%;
  display: flex;
}

.swipeable-list-item__content--return {
  transition: transform 0.5s ease-out;
}

.swipeable-list-item__content--remove {
  transition: transform 0.35s ease-in;
}

.swipeable-list-item__content-right {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;
}

.swipeable-list-item__content-right--return {
  transition: opacity 0.5s ease-out;
}

.swipeable-list-item__content-left {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;
  justify-content: flex-end;
}

.swipeable-list-item__content-left--return {
  transition: opacity 0.5s ease-out;
}

// Animation Tutorial
.swipeable-list {
  .swipeable-list-item:first-of-type {
    .swipeable-list-item__content-right {
      animation: right 3.3s ease-in;
    }
    .swipeable-list-item__content-left {
      animation: left 4.2s ease-in;
    }
    .swipeable-list-item__content {
      animation: left-to-right 4.2s ease-in;
    }

    @keyframes left-to-right {
      20% {
        transform: translateX(70%);
      }
      80% {
        transform: translateX(-50%);
      }
    }

    @keyframes right {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }

    @keyframes left {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
}

// .animation-list {
//   background: blue;
// }
